
	import { Component, Prop, Vue, Watch } from "vue-property-decorator";

	@Component
	export default class FloorSelector extends Vue {
		@Prop() readonly dataset!:{ name:string, id:number }[];
		currentDataset = this.dataset;
		showAllFloor:boolean = true;

		get currentFloor ():number {
			return this.$store.state.floorValue;
		}

		setActive (id:number) {
			if(this.$store.state.floorValue == id ){
				return;
			}
			this.$store.commit("fChangeFloor", { type: this.$store.state.showType, id });
			// @ts-ignore
			// document.getElementById("frame").contentWindow.gameInstance.SendMessage("Canvas", "fChangeFloor",id);
		}

		showAll () {
			if (this.$store.state.showType !== "device") {
				this.showAllFloor = true;
				this.$store.commit("changeViewByCode", { type: this.$store.state.showType, id: "" });
			}
		}

		@Watch("dataset")
		onDatasetChange (value:{ name:string, id:number }[]) {
			this.currentDataset = value;
			this.$forceUpdate()
		}
	}
