
	import { Component, Vue } from "vue-property-decorator";
	import FloorSelector from "@/components/floorSelector/floorSelector.vue";
	import request from "@/request";



	@Component({
		components:{
			FloorSelector
		}
	})
	export default class MedicalDataIndex extends Vue {
		floors:{ name:string, id:number }[] = [];
		mounted(){
			request({
				url: "/space/floor/",
				params: {
					facility: 1
				}
			}).then(({ data }) => {
				this.floors = data.reverse().map((t:any) => ({
					name: t.name,
					id: t.id
				}));
			});
		}

	}

