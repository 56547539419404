import { render, staticRenderFns } from "./floorSelector.vue?vue&type=template&id=96b2b672&scoped=true&"
import script from "./floorSelector.vue?vue&type=script&lang=ts&"
export * from "./floorSelector.vue?vue&type=script&lang=ts&"
import style0 from "./floorSelector.vue?vue&type=style&index=0&id=96b2b672&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "96b2b672",
  null
  
)

export default component.exports